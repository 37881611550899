@import "@/styles/_variables.scss";


































































































































































































































































































































































































































.name {
  cursor: pointer;
  text-decoration: none;
  color: var(--app-primary-color);
}
.name:hover {
  color: var(--text-color);
}
.fixed {
  height: 55vh;
}
